import {memoize} from 'lodash'
import * as userConstants from 'constants/user.constants'
import {
  ILoginRequest,
  ICheckUserRequest,
  IEmailExistRequest,
  ISignupVerificationRequest,
  IPhoneExistRequest,
  ISendNewPasswordRequest,
  ISignup,
  IUserMeta,
  IUploadDocument,
  ISendEmailRequest,
  EContactOptions,
} from 'model/user.model'
import {api, firebaseApi} from './api'
import { USER_ACTIVITY_ENDPOINT } from "constants/user.constants";

type TSagaCall = (...args: any[]) => any

export const loginUser = ((payload: ILoginRequest) => api.post(userConstants.LOGIN_ENDPOINT, payload)) as TSagaCall

export const sendUserActivity = ((payload: any) => api.post(userConstants.USER_ACTIVITY_ENDPOINT, payload))

export const signupUser = ((payload: ISignup) => api.post(userConstants.SIGNUP_ENDPOINT, payload)) as TSagaCall

export const editUser = ((payload: IUserMeta) => api.post(userConstants.EDIT_USER, payload)) as TSagaCall

export const checkUserExist = ((payload: ICheckUserRequest) => api.post(userConstants.CHECK_USER_EXIST, payload)) as TSagaCall

export const checkPhoneExist = ((payload: IPhoneExistRequest) => api.post(userConstants.CHECK_PHONE_EXIST, payload)) as TSagaCall

export const checkEmailExist = ((payload: IEmailExistRequest) => api.post(userConstants.CHECK_EMAIL_EXIST, payload)) as TSagaCall

export const getResetPasswordLink = ((payload: { phoneNumber: string; origin: string }) => api.post(userConstants.RESET_PASSWORD_LINK, payload)) as TSagaCall

export const checkLinkResetPassword = ((payload: { token: string }) => api.post(userConstants.CHECK_RESET_PASSWORD_LINK, payload)) as TSagaCall

export const sendResetPassword = ((payload: ISendNewPasswordRequest) => api.post(userConstants.RESET_PASSWORD, payload)) as TSagaCall

export const sendSignupVerification = ((payload: ISignupVerificationRequest) => api.post(userConstants.SEND_SIGNUP_VERIFICATION, payload)) as TSagaCall

export const validateNewPhone = (payload: object) => api.post('/mobile/user/change_phone/new_phone_validate', payload)

export const uploadDocument = ({file, description}: IUploadDocument) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('description', description)

  return api.post(userConstants.UPLOAD_DOCUMENT, formData)
}

export const getUserDocumentsList = (() => api.post(userConstants.GET_DOCUMENTS, {})) as TSagaCall

export const getDocumentById = (id: string) => api.get(`user/getDocument?id=${id}`, {responseType: 'blob'})

export const sendMessageAuthUser = (payload: { userId: string; supportMessage: string }) => api.post('organization/contact-support', payload)

export const sendEmailMessage = (payload: ISendEmailRequest) => api.post(userConstants.SEND_EMAIL_MESSAGE, payload)

export const getUserData = () => api.get(userConstants.GET_USER_DATA)

export const saveUserContactOptions = (payload: { userContactOptionsList: EContactOptions[] }) =>
  api.post(userConstants.SET_CONTACT_OPTIONS, payload)

export const changePassword = (payload: { oldPassword: string; newPassword: string }) => api.post(userConstants.CHANGE_PASSWORD, payload)

export const approveActivePhone = (payload: {smsCode: string, sessionId: string}) =>
  api.post('/mobile/user/change_phone/active_phone_approve', payload);

export const approveNewPhone = (payload: {smsCode: string, sessionId: string}) =>
  api.post('/mobile/user/change_phone/new_phone_approve', payload);

export const checkVerifiedProfile = (userId: string) => api.get(`/users/${userId}/compliance-status`, {responseType: 'text'})

export const setUserLanguage = (language: string) => api.post('/user/setLanguage', {language});

export const getListNotifications = memoize(
  async function (payload, authToken: string | undefined) {
    const {userId, page, filter, token} = payload;
    return firebaseApi.get(`${userId}?page=${page || 0}&filter=${filter || ''}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      }
    })
  },
  (...args) => JSON.stringify(args)
)

export const getNotificationsQuantity = (userId: string, token: string | undefined) =>
  firebaseApi.get(`getQuantity?userId=${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  })

export const setDeviceToken = (payload: {userId: string, token: string, type: string},
                               authToken: string | undefined) =>
  firebaseApi.post(`setDeviceToken`, payload, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    }
  })

export const deleteNotifications = (query: string, token: string | undefined) =>
  firebaseApi.delete(query,{
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  })
